import BackgroundImage from "gatsby-background-image";
import React, { useState, useEffect } from "react"
import { AiOutlineCloseSquare } from "react-icons/ai"
import "./exit-intent-modal.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import imgpng from "../../assets/whitePng.png";
import imgwebp from "../../assets/whiteWebp.webp";
import CustomMarkdown from "../../utils/customMarkdown";

const ExitIntentModal = (props) => {


    const [show, setShow] = useState(props.show)
    useEffect(() => {
        if (props.show) {
            let expiryDate = new Date(
                Date.now() + 14 * (1000 * 60 * 60 * 24)
            )
            expiryDate.setFullYear(expiryDate.getFullYear() + 1)
            document.cookie =
                "modal_seen" + "=true; expires=" + expiryDate.toUTCString()
            document.body.setAttribute('style', `position: fixed; top: 0; left: 0; right: 0;`)
        }
        setShow(props.show)
    }, [props.show])


    const close = e => {
        if (e.target.className === 'exit-intent-popup' || // user clicks on mask
            e.target.className === 'close') {             // user clicks close btn
            document.body.setAttribute('style', ``)
            setShow(false)
        }
    }





    const [email, setEmail] = useState("");
    const [form, setForm] = useState();

    const [error, setError] = useState(false);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const validate = (e) => {

        let emailError = "";
        let isNotValid = false;

        if (form.email === "" || isValidEmail(form.email) === false) {
            emailError = "This field is required.";
            isNotValid = true;
        }

        return isNotValid;
    }

    const result = e => {
        let value = validate();
        setError(value);
    }


    (function () {
        let fnames = new Array();
        let ftypes = new Array();
        fnames[0] = 'EMAIL';
        ftypes[0] = 'email';
        fnames[1] = 'FNAME';
        ftypes[1] = 'text';
        fnames[2] = 'LNAME';
        ftypes[2] = 'text';
        fnames[3] = 'ADDRESS';
        ftypes[3] = 'address';
        fnames[4] = 'PHONE';
        ftypes[4] = 'phone';
        fnames[5] = 'BIRTHDAY';
        ftypes[5] = 'birthday';
    }
        ());

    var bgFluidImage = { fluid: { srcWebp: imgwebp, src: imgpng } }


    return (
        <>
            {
                (show && props.enableIntent) ?
                    <div className="exit-intent-popup" onClick={(e) => close(e)}>
                        <div className="newsletter">
                            <BackgroundImage Tag="section" role="img" backgroundColor={props.theme == "light" ? "#F4F6F9" : "#1f2f57"} fluid={props.bgImage.fluid != null ? props.bgImage.fluid : props.theme == "light" ? ["#F4F6F9"] : ["#1f2f57"]} className="popup-bg">
                                <div className="popup-content">

                                    {props.icon !== null ?
                                        <div className="modal-subscribe-icon">
                                            <GatsbyImage className="modal-img" image={props.icon.gatsbyImageData} alt={""} style={{ width: '200px' }}></GatsbyImage>
                                        </div>
                                        : ""}

                                    <div className={props.theme == "light" ? "modal-subscribe-content" : "modal-subscribe-content-dark"}>
                                        <b>{props.intentTitle}</b>
                                        {props.intentDescription && props.intentDescription.split('#').length > 1 ?
                                            <div style={{ padding: "30px 0px" }}>
                                                <p>{props.intentDescription.split('#')[0]}</p>
                                                <CustomMarkdown>{props.intentDescription.split('#')[1]}</CustomMarkdown>
                                            </div>
                                            :
                                            <div style={{ padding: "30px 0px" }}>
                                                <p>{props.intentDescription}</p>
                                            </div>
                                        }
                                        {
                                            props.ctaUrl != "" ?
                                                <div className="centered-modal-fields">
                                                    <a href={props.ctaUrl} className={props.theme == "light" ? "intent-button button button-primary" : "intent-button-dark button button-primary"}>{props.ctaLabel}</a>
                                                </div>
                                                : ""
                                        }

                                    </div>
                                    <span className="close" onClick={(e) => close(e)}>&times;</span>


                                </div>
                            </BackgroundImage>
                        </div>

                    </div>
                    : ""
            }
        </>
    )

}

export default ExitIntentModal
